<template>
  <div class="page">
    <el-main v-show="!serviceItemModelShow">
      <el-container>
        <el-row>
          <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form :inline="true">
              <el-input
                v-model="keyword"
                placeholder="请输入关键字查询"
                class="input-with-select"
                style="width: 300px; margin-right: 10px"
                size="medium"
              >
                <template #prepend>
                  <el-select
                    v-model="checkInput"
                    multiple
                    collapse-tags
                    placeholder="请选择"
                    style="width: 120px"
                  >
                    <el-option
                      v-for="(item, i) in dataTableSearch"
                      :key="i"
                      :label="item.label"
                      :value="item.attr"
                    ></el-option>
                  </el-select>
                </template>
              </el-input>
              <slot name="searchSlot"></slot>
              <el-form-item>
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  size="medium"
                  @click="SearchPage()"
                  >查询</el-button
                >
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="medium"
                  v-has="'Add'"
                  @click="serviceItemAdd"
                  >创建服务项目</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-popover placement="bottom" :width="400" trigger="click">
                  <template #reference>
                    <el-button size="medium"
                      ><i class="el-icon-arrow-down el-icon-menu" /> 列筛选</el-button
                    >
                  </template>
                  <el-checkbox-group v-model="dataTableSearchList">
                    <div v-for="(item, i) in dataTableList" :key="i">
                      <el-checkbox
                        v-if="item.visible != false"
                        :label="item"
                        checked
                        style="display: block; margin: 10px"
                        @change="filterFunHandle('filter', item.attr, i)"
                      >
                        {{ item.label }}</el-checkbox
                      >
                      <el-checkbox
                        v-else
                        :label="item"
                        style="display: block; margin: 10px"
                        @change="filterFunHandle('filter', item.attr, i)"
                      >
                        {{ item.label }}</el-checkbox
                      >
                    </div>
                  </el-checkbox-group>
                  <el-button
                    size="small"
                    type="text"
                    @click="filterFunHandle('allchecked')"
                    >全选</el-button
                  >
                  <el-button size="small" type="text" @click="filterFunHandle('cancel')"
                    >取消全选</el-button
                  >
                </el-popover>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-table
              size="small"
              border
              ref="multipleTable"
              :data="dataTable"
              style="width: 99%"
              max-height="700"
              :height="tableHeight"
              :fit="true"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                fixed="left"
                header-align="center"
                align="center"
              ></el-table-column>
              <el-table-column
                label="操作"
                fixed="right"
                header-align="center"
                align="center"
                width="260"
              >
                <template #default="scope">
                  <el-button
                    v-has="'Update'"
                    size="mini"
                    @click="serviceItemEdit(scope.row.Id)"
                    >编辑</el-button
                  >
                  <el-popconfirm
                    confirmButtonText="确定"
                    cancelButtonText="取消"
                    icon="el-icon-info"
                    iconColor="red"
                    title="确定删除吗？"
                    @confirm="serviceItemDelete(scope.row.Id)"
                  >
                    <template #reference>
                      <el-button type="danger" v-has="'FakeDelete'" size="mini"
                        >删除</el-button
                      >
                    </template>
                  </el-popconfirm>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(col, index) in dataTableSearchList"
                :key="index"
                sortable
                header-align="center"
                align="center"
                :prop="col.attr"
                :label="col.label"
              >
                <template #default="scope">
                  <el-image
                    v-if="col.type == 'img'"
                    :src="scope.row[col.attr]"
                    :preview-src-list="[scope.row[col.attr]]"
                    fit="fill"
                    style="width: 40px; height: 40px"
                  ></el-image>
                  <el-tag size="medium" v-else-if="col.type == 'enum'">
                    {{ col.formatters(scope.row[col.attr]) }}
                  </el-tag>
                  <el-switch
                    v-else-if="col.type == 'switch'"
                    v-model="scope.row[col.attr]"
                    active-text="是"
                    inactive-text="否"
                    @change="IsCloseServiceItem(scope.row['Id'])"
                  ></el-switch>
                  <label v-else>{{ scope.row[col.attr] }}</label>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <paging
              :currentPage="currentPage"
              :pagesize="pageSize"
              :total="total"
              @sizeChange="handleSizeChange"
              @currentChange="handleCurrentChange"
              :pagesizes="pageSizes"
            />
          </el-col>
        </el-row>
      </el-container>
    </el-main>
    <el-main v-show="serviceItemModelShow" class="cs">
      <h2>{{ serviceItemModelTitle }}</h2>
      <el-form
        label-position="top"
        label-width="100px"
        :model="serviceItemForm"
        :rules="serviceItemFormRules"
        ref="serviceItemForm"
        style="max-width: 800px"
      >
        <el-form-item label="项目图片" prop="ItemPic">
          <uploaderimgfile
            v-model="serviceItemForm.ItemPic"
            :imageUrl="imageUrl"
            @handleAvatarSuccess="handleAvatarSuccess"
          />
        </el-form-item>
        <el-form-item label="项目名称" prop="ItemName">
          <el-input v-model="serviceItemForm.ItemName" placeholder="请输入项目名称" />
        </el-form-item>
        <el-form-item label="项目金额" prop="ItemMoney">
          <el-input v-model="serviceItemForm.ItemMoney" placeholder="请输入项目金额" />
        </el-form-item>
        <el-form-item label="项目类型" prop="ItemServiceType">
          <el-select
            v-model="serviceItemForm.ItemServiceType"
            placeholder="请选择项目类型"
          >
            <el-option
              v-for="item in ServiceTypeOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="到期类型" prop="ItemExpirationType">
          <el-switch
            style="display: block"
            v-model="serviceItemForm.ItemExpirationType"
            active-color="#13ce66"
            inactive-color="#409EFF"
            active-text="固定到期"
            inactive-text="灵活到期"
            active-value="0"
            inactive-value="1"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="服务期" prop="ItemDate">
          <el-date-picker
            v-model="serviceItemForm.ItemDate"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="YYYY 年 MM 月 DD 日 HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            unlink-panels
            @change="SelectDate()"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注" prop="RemarkInfo">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入备注"
            v-model="serviceItemForm.RemarkInfo"
          ></el-input>
        </el-form-item>
        <el-form-item label="项目内容" prop="ItemContent"></el-form-item>
        <vue-ueditor-wrap
          v-model="serviceItemForm.ItemContent"
          :config="editorConfig"
          editor-id="noticeUeditor"
          :ready="onEditorReady"
          max-height="750"
        />
        <el-form-item label="是否关闭办理" prop="IsClose">
          <el-switch
            style="display: block"
            v-model="serviceItemForm.IsClose"
            active-color="#13ce66"
            inactive-color="#409EFF"
            active-text="是"
            inactive-text="否"
          >
          </el-switch>
        </el-form-item>
        <el-form-item style="margin-top: 10px">
          <el-button @click="resetForm('serviceItemForm')">取消</el-button>
          <el-button type="primary" @click="saveServiceItem('serviceItemForm')"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </el-main>
  </div>
</template>
<script>
const UEDITOR_HOME_URL =
  process.env.NODE_ENV === "development" ? "/UEditor/" : `/UEditor/`;
export default {
  name: "serviceItem",
  components: {},
  data() {
    return {
      editorConfig: {
        // 上传文件接口（
        serverUrl: `${this.$CONFIG.API_UEditor_Upload_URL}`,
        // 访问 UEditor 静态资源的根路径，开发环境是在 http://192.168.3.200:8081/UEditor/，部署到 GitHub Pages 之后的路径就是 /vue-ueditor-wrap/UEditor/ 了
        UEDITOR_HOME_URL,
        // 自定义列表标号图片的地址，默认是 http://bs.baidu.com/listicon/，不过默认链接下的列表小图片都已经 404 了，所以下载了一份放到项目里啦
        listiconpath: `${UEDITOR_HOME_URL}listicon/`,
      },
      imageUrl: {
        Id: 0,
        Url: "",
      },
      ServiceTypeOptions: [
        {
          name: "智慧校园",
          value: 0,
        },
        {
          name: "门禁考勤",
          value: 1,
        },
        {
          name: "校园消费",
          value: 2,
        },
        {
          name: "亲情通话",
          value: 3,
        },
      ],
      serviceItemForm: {
        Id: 0,
        ItemName: "",
        ItemPic: "",
        ItemMoney: "",
        ItemContent: "",
        ItemBeginTime: "",
        ItemEndTime: "",
        IsClose: false,
        ItemExpirationType: 1,
        ItemServiceType: 0,
        RemarkInfo: "",
        ItemDate: "",
      },
      serviceItemFormRules: {
        ItemPic: [{ required: true, message: "请上传项目图片", trigger: "blur" }],
        ItemName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "长度在2 到 50 个字符",
            trigger: "blur",
          },
        ],
        ItemMoney: [{ required: true, message: "请输入项目金额", trigger: "blur" }],
        ItemBeginTime: [
          { required: true, message: "请选择项目开始时间", trigger: "blur" },
        ],
        ItemEndTime: [{ required: true, message: "请选择项目结束时间", trigger: "blur" }],
        ItemDate: [{ required: true, message: "请选择服务期", trigger: "blur" }],
        IsClose: [{ required: true, message: "请选择是否关闭", trigger: "blur" }],
        ItemExpirationType: [
          { required: true, message: "请选择服务到期类型", trigger: "blur" },
        ],
        ItemServiceType: [{ required: true, message: "请选择项目类型", trigger: "blur" }],
        RemarkInfo: [
          {
            min: 0,
            max: 100,
            message: "长度在0 到 100 个字符",
            trigger: "blur",
          },
        ],
        ItemContent: [{ required: true, message: "请输入项目内容", trigger: "blur" }],
      },
      serviceItemModelShow: false,
      serviceItemModelTitle: "",
      serviceItemId: 0,
      total: 0, //数据总条数
      pageSize: 10, //当前显示数据数量
      pageSizes: [10, 20, 50, 100],
      currentPage: 1, //分页当前页
      keyword: "", //搜索输入关键字
      checkInput: "", //选择所需要查询的内容
      dataConfig: [
        {
          label: "Id",
          attr: "Id",
          isInput: false,
          isDisplay: false,
        },
        {
          label: "名称",
          attr: "ItemName",
          isSearch: true,
        },
        { label: "图片", attr: "ItemPicUrl", type: "img" },
        { label: "金额（元）", attr: "ItemMoney" },
        { label: "开始时间", attr: "ItemBeginTime" },
        { label: "结束时间", attr: "ItemEndTime" },
        {
          label: "是否关闭办理",
          attr: "IsClose",
          type: "switch",
        },
        {
          label: "到期类型",
          attr: "ItemExpirationType",
          type: "enum",
          formatters: function (val) {
            var result;
            switch (val) {
              case 0:
                result = "固定到期";
                break;
              case 1:
                result = "灵活到期";
                break;
              default:
                result = "未知";
                break;
            }
            return result;
          },
        },
        {
          label: "项目类型",
          attr: "ItemServiceType",
          type: "enum",
          formatters: function (val) {
            var result;
            switch (val) {
              case 0:
                result = "智慧校园";
                break;
              case 1:
                result = "门禁考勤";
                break;
              case 2:
                result = "校园消费";
                break;
              case 3:
                result = "亲情话机";
                break;
              default:
                result = "未知";
                break;
            }
            return result;
          },
        },
        {
          label: "内容",
          attr: "ItemContent",
          isInput: false,
          isSearch: true,
        },
        {
          label: "备注",
          attr: "RemarkInfo",
          isInput: false,
        },
        {
          label: "创建时间",
          attr: "CreatedTime",
          isInput: false,
          visible: false,
        },
        {
          label: "更新时间",
          attr: "UpdatedTime",
          isInput: false,
          visible: false,
        },
      ],
      dataTableSearch: [], //搜索信息
      dataTableList: [], //显示列信息
      dataTableSearchList: [], //列筛选信息
      dataTable: [], //显示数据
      multipleSelection: [], //选中数据
      tableHeight: 200, //table高度
    };
  },
  mounted() {
    const that = this;
    that.$nextTick(function () {
      // 仅在整个视图都被渲染之后才会运行的代码
      //设置table位置
      let heightStyle = window.innerHeight - 230;
      that.tableHeight = heightStyle;

      //窗口大小改变时，table 高度设置
      window.onresize = () => {
        return (() => {
          let heightStyle = window.innerHeight - 230;
          that.tableHeight = heightStyle;
        })();
      };
    });
    that.SearchPage();
    that.dataTableSearch = that.dataConfig.filter((it) => it.isSearch == true); //可搜索字段
    that.dataTableList = that.dataConfig.filter((it) => it.isDisplay != false); //可显示列
  },
  methods: {
    SelectDate() {
      this.serviceItemForm.ItemBeginTime = this.serviceItemForm.ItemDate[0];
      this.serviceItemForm.ItemEndTime = this.serviceItemForm.ItemDate[1];
    },
    handleAvatarSuccess(res) {
      if (res.Success && res.Code == 200) {
        this.imageUrl = res.Data;
        this.serviceItemForm.ItemPic = this.imageUrl.Id;
      } else {
        console.log(res.Message);
      }
    },
    //页面搜索查询
    SearchPage() {
      let that = this;
      let json = {
        searchValue: "",
        pageNo: that.currentPage,
        pageSize: that.pageSize,
        searchBeginTime: that.searchStartTime,
        searchEndTime: that.searchEndTime,
        sortField: "",
        sortOrder: "",
        descStr: "",
      };
      if (that.checkInput.length > 0 && that.keyword != "") {
        var searchParameters = [],
          input = that.keyword;
        for (let index = 0; index < that.checkInput.length; index++) {
          const element = that.checkInput[index];
          var searchParametersItem = {
            field: element,
            op: 8,
            value: input,
            orGroup: "",
          };
          searchParameters.push(searchParametersItem);
        }
        json.searchParameters = searchParameters;
      }

      that.$API.ServiceItem.QueryByPage.post(json).then((res) => {
        if (res) {
          that.total = res.TotalRows;
          that.pagesize = res.PageSize;
          that.currentPage = res.PageNo;
          that.dataTable = res.Rows;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.SearchPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.SearchPage();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 列筛选
    filterFunHandle(type, currentItem, index) {
      if (type === "allchecked") {
        // 全选
        this.dataTableSearchList = this.dataConfig.filter((it) => it.isDisplay != false);
      } else if (type === "cancel") {
        // 取消全选
        this.dataTableSearchList = []; // 复选框置为空，全部不选择
      }
    },
    serviceItemAdd() {
      this.serviceItemModelTitle = "创建服务项目";
      this.serviceItemId = 0;
      this.serviceItemForm = {
        Id: 0,
        ItemName: "",
        ItemPic: "",
        ItemMoney: "",
        ItemContent: "",
        ItemBeginTime: "",
        ItemEndTime: "",
        IsClose: false,
        ItemExpirationType: 1,
        ItemServiceType: 0,
        RemarkInfo: "",
        ItemDate: "",
      };
      this.imageUrl = {
        Id: 0,
        Url: "",
      };
      this.serviceItemModelShow = true;
    },
    serviceItemEdit(id) {
      this.serviceItemId = id;
      this.serviceItemModelTitle = "修改服务项目";
      var that = this;
      that.$API.ServiceItem.QueryById.get(id).then((res) => {
        if (res) {
          that.serviceItemForm.Id = res.Id;
          that.serviceItemForm.ItemName = res.ItemName;
          that.serviceItemForm.ItemPic = res.ItemPic;
          that.serviceItemForm.ItemMoney = res.ItemMoney;
          that.serviceItemForm.ItemContent = res.ItemContent;
          that.serviceItemForm.ItemBeginTime = res.ItemBeginTime;
          that.serviceItemForm.ItemEndTime = res.ItemEndTime;
          that.serviceItemForm.IsClose = res.IsClose;
          that.serviceItemForm.ItemExpirationType = res.ItemExpirationType;
          that.serviceItemForm.ItemServiceType = res.ItemServiceType;
          that.serviceItemForm.RemarkInfo = res.RemarkInfo;
          that.serviceItemForm.ItemDate = res.ItemDate;
          that.imageUrl.Id = res.ItemPic;
          that.imageUrl.Url = res.ItemPicUrl;
          that.serviceItemModelShow = true;
        }
      });
    },
    saveServiceItem(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          const loading = that.$loading({
            lock: true,
            text: "数据处理中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          that.$API.ServiceItem.AddOrUpdate.post(that.serviceItemForm).then((res) => {
            loading.close();
            if (res.Success) {
              that.$message.success(res.Message);
              that.resetForm(formName);
              this.SearchPage();
            } else {
              that.$message.error(res.Message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.serviceItemModelShow = false;
      this.serviceItemId = 0;
      this.$refs[formName].resetFields();
    },

    deleteServiceItem() {
      var that = this;
      if (!that.multipleSelection || that.multipleSelection.length <= 0) {
        that.$message.warning("请选择需要删除的通知！");
        return;
      }
      var ids = new Array();
      var msgStr = "";
      that.multipleSelection.forEach((it) => {
        ids.push(it.Id);
        msgStr += `【${it.Title}】`;
      });
      that
        .$confirm(`确认删除${msgStr}等${that.multipleSelection.length}个通知信息吗？`)
        .then(() => {
          that.$API.ServiceItem.FakeDelete.delete(ids).then((res) => {
            if (res.Success) {
              that.$message.success(res.Message);
              that.SearchPage();
            }
          });
        })
        .catch(() => {});
    },
    serviceItemDelete(id) {
      var that = this;
      that.$API.ServiceItem.FakeDelete.delete([id]).then((res) => {
        if (res.Success) {
          that.$message.success(res.Message);
          that.SearchPage();
        }
      });
    },
    IsCloseServiceItem(id) {
      var that = this;
      that.$API.ServiceItem.IsCloseServiceItem.post(id).then((res) => {
        if (res.Success) {
          that.$message.success(res.Message);
          that.SearchPage();
        } else {
          that.$message.error(res.Message);
        }
      });
    },

    operationSuccess() {
      //操作成功后，关闭弹出框，刷新数据显示
      this.serviceItemId = 0;
      this.SearchPage();
    },
    handleClose(done) {
      var that = this;
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.page {
  margin: 5px;
  padding: 10px;
  min-height: 840px;
  width: 95%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  width: auto;
}
.drawerTitle {
  color: #409eff;
}
.cs {
  height: 840px;
  max-height: 840px;
}
</style>
